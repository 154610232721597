import { useState } from 'react'
import styled from 'styled-components'
import { Grid, Divider } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProductBoxMobile from './components/ProductBoxMobile';
import history from 'modules/app/components/history'
import TrustBoxMobile from './components/TrustBoxMobile'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import OverviewBoxMobile from './components/OverviewBoxMobile'
import BuyersGuideBoxMobile from './components/BuyersGuideBoxMobile'

const MobileDiv = (props: any) => {
    const { items, title, product, overview, buyer_guide, buyer_guide_title, buyer_guide_image_url, isHoverDisabled, setIsTooltipVisible, handleClick, isTooltipVisible, tooltipRef, click_got_it } = props

    const goStartPage = () => {
        history.push(`/`)
    }

    return (
        <CustomWrapperItems>
          <CustomGrid container spacing={0} >
            <CustomGrid item container spacing={0} md={12} className="grid-header-left grid-flex-item-start category_mobile_pd">
              <CustomGrid item container spacing={0} className="margin-padding-0_v-center category_mobile_pd">
                <CustomGrid item sm={12} className="margin-padding-0_v-center">
                  <ShortSpan onClick={goStartPage}>
                      <HomeIcon  />
                  </ShortSpan>
                  <span style={{color: 'gray', fontSize: '14px' }}>&nbsp;&nbsp;✦&nbsp;&nbsp;</span>
                  <ShortSpan>
                      {title}
                  </ShortSpan>
                  <span style={{color: 'gray', fontSize: '14px' }}>&nbsp;&nbsp;✦&nbsp;&nbsp;</span>
                  <ShortSpan className="lastSpan">
                      {product}
                  </ShortSpan>
                </CustomGrid>
              </CustomGrid>
              <CustomGrid item container spacing={0} className="margin-padding-0_v-center category_mobile_pd">
                  <CustomGrid item  sm={12} className="margin-padding-0_v-center">
                      <CategoryTitle>Best {product}</CategoryTitle>
                  </CustomGrid>
              </CustomGrid>
              <CustomGrid item container spacing={0} className="margin-padding-0_v-center category_mobile_pd">
                  <CustomGrid item sm={12} className="margin-padding-0_v-center">
                      <UpdatedSpan>(Updated February 2025)</UpdatedSpan>
                  </CustomGrid>
              </CustomGrid>
              <CustomGrid item container spacing={0} className="advertising_grid category_mobile_pd_01">
                  <CustomGrid item sm={12} className="category_mobile_pd_01">
                    <div className='advertising_grid_wrapper'>
                      <div 
                          className='advertising_grid_text'
                          onMouseEnter={() => !isHoverDisabled && setIsTooltipVisible(true)}
                          onMouseLeave={() => !isHoverDisabled && setIsTooltipVisible(false)}
                          onClick={handleClick}
                      >
                          Advertising Disclosure &nbsp;
                          <img src={require('assets/arrow_bottom_buyer.svg').default} />
                      </div>
                      {
                          isTooltipVisible && (
                              <div className='advertising_text_tooltip'  ref={tooltipRef}>
                                  <p>This site is a free online resource that strives to offer helpful content and comparison features to its visitors. Please be advised that the operator of this site accepts advertising compensation from companies that appear on the site, and such compensation  impacts the location and order in which the companies (and/or their products).</p>
                                  <p>In addition, the Operating Agreement requires that the following statement clearly and conspicuously appears on your Site: &quot;As an Amazon Associate I earn from qualifying purchases.&quot; For social media user-generated content, this statement must be associated with your account.</p>  
                                  <div className='advertising_got_it_btn' onClick={click_got_it}>
                                      GOT IT
                                  </div>
                              </div>
                          )
                      }
                      
                    </div>
                  </CustomGrid>
              </CustomGrid>
            </CustomGrid>
              
            </CustomGrid> 
            <Divider sx={{ boxShadow: '0px 1px 2px #0a0909'}} />
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <CustomGrid container spacing={0} sx={{background: 'transparent!important', marginTop: '5px!important', padding: '0!important'}}>
                <CustomGrid item container spacing={0} sm={12} className="grid-header-left grid-header-right grid-flex-item-start" sx={{background: 'transparent!important'}} >
                    {   items.length !== 0 ?
                        items.length > 0 && items.map((item: any, index: number)=> {
                            return <ProductBoxMobile key={index} item={item} num={index + 1}/>
                        }) : ''
                    }
                </CustomGrid>
              </CustomGrid>
            </Box>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <CustomGrid container spacing={0} sx={{background: 'transparent!important', marginTop: '20px!important', justifyContent: 'center', maxWidth: '1320px'}}>
                <CustomGrid item container spacing={0} className=""  style={{padding: '0'}}>
                    <CustomGrid item  sm={12} className="margin-padding-0_v-center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <CategoryTitle>{product} Buyer's Guide</CategoryTitle>
                        <span className='buyer_guide_title_sub'>{ buyer_guide_title }</span>
                    </CustomGrid>
                </CustomGrid>
                <CustomGrid item container spacing={0} className="" style={{padding: '0'}}>
                    <CustomGrid item sm={12} className="img_wrapper_grid">
                        <div className="img_div_wrapper">
                            <span className="img">
                              {
                                  buyer_guide_image_url === undefined || buyer_guide_image_url === null || buyer_guide_image_url?.trim() === '' ?
                                  items.length > 0 ? 
                                  <img src={items[0].Images?.Primary?.Large?.URL} alt="" title="" />
                                  :  
                                  <></>
                                  // <img src="https://www.buyersreport.org/cdn-cgi/image/width=900,f=auto,quality=85,onerror=redirect/https://d1ccm0vckus47s.cloudfront.net//home/ubuntu/www/releases/20230913173000/tmp/uploads/1694634426-101040460748555-0001-6035/image.png" alt="" title="" />
                                  :
                                  <img src={buyer_guide_image_url} alt="" title="" />
                              }
                                {/* <img src="https://www.buyersreport.org/cdn-cgi/image/width=900,f=auto,quality=85,onerror=redirect/https://d1ccm0vckus47s.cloudfront.net//home/ubuntu/www/releases/20230913173000/tmp/uploads/1694634426-101040460748555-0001-6035/image.png" alt="" title="" /> */}
                            </span>
                        </div>
                    </CustomGrid>
                </CustomGrid>

                <CustomGrid item md={12} className="grid-header-right grid-flex-item-start">
                  <CustomBox className="parent_box">
                    <CustomBox className="center_box">
                      <Box className="main_box">
                        <span className='overview_text'>
                            {overview}
                        </span>
                      </Box>
                    </CustomBox>
                  </CustomBox>
                </CustomGrid>

                <CustomGrid item container spacing={0} className="">
                    <CustomGrid item sm={12} className="margin-padding-0_v-center">
                        <UpdatedSpan className='buyer_guide_span'>{buyer_guide}</UpdatedSpan>
                    </CustomGrid>
                </CustomGrid>
              </CustomGrid>
            </Box>
            

        </CustomWrapperItems> 
    );
};

const CustomBox = styled(Box)`
  &.tab_box {
    span.css-1aquho2-MuiTabs-indicator {
      background-color: #1e947e!important;
    }
    span.css-ttwr4n {
      background-color: #1e947e!important;
    }
  }

  &.parent_box {
    width: 100%;
    display: flex;
    background: aliceblue;
    margin: 10px 0;
    border-radius: 10px;
    position: relative;
    .center_box {
        background: white;
        margin: 0;
        width: auto;
        flex-grow: 1;
        display: flex!important;
        align-items: center!important;
        justify-content: start!important;
        // padding: 20px 15px;
        img {
            width: 133px;
            height: 24px;
            margin-top: 5px;
          }
        .main_box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .overview_text {
                color: #636060;
                font-family: "Roboto","Helvetica","Arial",sans-serif;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5;
                letter-spacing: 0.00938em;
            }
        }
    }
  }
`

const CustomChevronRightIcon = styled(ChevronRightIcon)`
    margin: 0 5px;
`

const ImageAndTextM = styled.div`
  display: flex;
  align-items: center;
  // margin-bottom: 5px;
  img {
    // width: 25px!important;
    // height: 25px!important;
    margin-right: 5px;
  }
`

const CardSubTextM = styled.p`
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    color: #187664;
    // color: #06bbf1;
    // font-family: 'Montserrat',sans-serif;
    font-family: GT Pressura, sans-serif;
    margin: 0;
`

const CustomWrapperItems = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media (min-width: ${screenSizes.mediaM}px) {
        display: none;
    }
`

const CustomGrid = styled(Grid)`
&.advertising_grid {
    display: flex;
    justify-content: flex-end;
    // align-items: center;
    align-items: flex-end;

    div.advertising_grid_wrapper {
        position: relative;
    }

    div.advertising_grid_text {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        color: rgb(113, 110, 118);
    }
    
    div.advertising_text_tooltip {
        right: -40%;
        top: 45px;
        position: absolute;
        z-index: 2;
        background-color: #f1efe9;
        width: 160%;
        padding: 5px 20px 15px;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3rem;
        text-align: center;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: default;

        @media (max-width: ${screenSizes.mediaM}px) {
            right: 0%;
        }
    }

    div.advertising_got_it_btn {
        padding: 5px 10px;
        background-color: #f38009;
        border-radius: 5px;
        color: white;
        width: 80%;
        cursor: pointer;
    }

    div.advertising_got_it_btn:hover {
        background-color: #f79e41;
       
    }
  }

  &.category_mobile_pd {
        @media (max-width: ${screenSizes.mediaM}px) {
            // display: none;
            padding: 8px 0px!important;
        }
    }

  &.category_mobile_pd_01 {
        @media (max-width: ${screenSizes.mediaM}px) {
            // display: none;
            padding: 0px 0px!important;
        }
    }

  background-color: white!important;

  padding: 2px 10px;
  margin: 0px!important;

  img {
    // width: 192px;
    // height: 48px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
  }

  .grid-header-left {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-left: 105px!important;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-left: 155px!important;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-left: 185px!important;
    }
  }

  .grid-header-right {
    align-items: center;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }
  }

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }
  .margin-padding-0_v-center {
    display: flex;
    align-items: center;
    margin: 2px 0!important;
    padding: 0!important;
  }
 
  .imageTxt {
      margin: 0 10px 0 5px;
      color: gray!important;
  }

  span.buyer_guide_title_sub {
    letter-spacing: 0.01em;
    color: #747574;
    font-family: Inter,Arial,sans-serif;
    line-height: 1.5rem;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 16px;
  }

  .img_wrapper_grid {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
  }
  .img_div_wrapper {
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;

      span.img {
        display: flex;
        justify-content: center;
        border-radius: 20px;
        overflow: hidden;
        margin-bottom: 20px;
        text-align: center;

        img {
          object-fit: contain;
          width: 100%;
        }
      }
  }
`
const ShortSpan = styled.span`
    cursor: pointer!important;
    font-family: sans-serif;
    color: gray;
    &:hover {
        color: black;
    }
    &.lastSpan {
      font-size: 15px!important;
        // color: #187664!important;
        // color: #37a4f1!important;
    }
`
const CategoryTitle = styled.span`
    font-family: GT Pressura,sans-serif;
    color: black!important;
    font-weight: 600;
    font-size: 26px;
`
const UpdatedSpan = styled.span`
    color: #5e5c62;
    font-size: 24px;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 1rem;
    box-sizing: border-box;

    &.buyer_guide_span {
    font-size: 16px;
    }
`
const CustomTab = styled(Tab)`
    &.lowcasetab {
      text-transform: none!important;
      width: 50%!important;
        
    } 
    &.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
      color: #1e947e!important;
    }
    &.css-1q2h7u5.Mui-selected {
      color: #1e947e!important;
    }
`
export default MobileDiv