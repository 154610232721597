import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { save_example_to_logfile } from 'modules/api/market-api';
import history from 'modules/app/components/history'

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function GenerateExampleDialog(props:any) {
  const {row, selected_product_index, products, setProducts, question_arr} = props

  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSave = () => {
    try {
      (async () => {
        setIsLoading(true)
        let model_image_url = ''
        let model_product_url = ''

        if (row.model_name !== '') {
          for (let i = 0; i < row.models.length; i ++) {
            if (row.models[i].model_name === row.model_name) {
              model_image_url = row.models[i].image_url
              model_product_url = row.models[i].product_url
            } else {

            }
          }
        }

        let sample_obj = {"messages": [{"role": "user","content": [{"type": "text","text": "user_text"}]},{"role": "assistant","content": [{"type": "text","text": "assistant_text"}]}]}

        let temp_obj = {
                        "model_name": row.model_name,
                        "image_url": model_image_url,
                        "product_url": model_product_url,
                        "reason": row.generated_result_text_gpt_4.split('\n')
                      }
        // console.log(JSON.stringify(temp_obj))             
        sample_obj.messages[1].content[0].text = JSON.stringify(temp_obj)    
        // console.log(sample_obj)     
        
        let question_answer_str = ''

        let temp_questions = JSON.parse(JSON.stringify(question_arr))
        let temp_answers = JSON.parse(JSON.stringify(row.choice_arr))

        temp_questions.shift()
        temp_answers.shift()

        // console.log(temp_questions, temp_answers)

        question_answer_str = question_answer_str + '"\n'
        for (let i = 0; i < temp_questions.length; i++) {
          question_answer_str += 'question: '
          question_answer_str += temp_questions[i]
          question_answer_str += '\n'
          question_answer_str += 'answer: '
          question_answer_str += temp_answers[i]
          question_answer_str += '\n'
        }
        question_answer_str = question_answer_str + '"'
        
        let result_prompt = ' [question_and_choices] \n Based on the question and my answer pairs above, please decide which of the models below is best for me, and then, please tell me the detailed reason why the decided model is best for me in bullet point format(•) rather than dash format(-). \n models = [models] \n\n Please provide me only the json format result file'
        
        result_prompt = result_prompt.replaceAll("[question_and_choices]",question_answer_str);
        result_prompt = result_prompt.replaceAll("[models]",JSON.stringify(row.models));

        sample_obj.messages[0].content[0].text = result_prompt   
        // console.log(sample_obj)  

        // console.log(JSON.stringify(sample_obj))
 
        const result = await save_example_to_logfile(products[selected_product_index].log_file_name, JSON.stringify(sample_obj))

        if ( result.status === 'success' ) {
          setIsLoading(false)
          props.setOpen(false);

          history.push('/admin/examples-for-finetuning-training')
        } else if ( result.status === 'error' ) {
          setIsLoading(false)
          props.setOpen(false);
          history.push('/admin/signin')
        }

        
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
        >
          {
            isLoading === true ? 
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit"/>
              <p>&nbsp;&nbsp; Generating Example ...</p>
            </Backdrop>
            : <></>
          }
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Generate Example!
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              The example will be generated from this record, are you okay?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleSave}>Generate</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
    
  );
}