import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { update_item } from 'modules/api/market-api';

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function EditItemDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ edited_item_url, setEditedItemUrl ] = React.useState<string>("");
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  React.useEffect(() => {
    if(props.products.length > 0 && props.edit_item_index !== -1) {
      let acitve_index = props.products.findIndex((ele:any) => ele.active === true)

      if (props.products[acitve_index].items[props.edit_item_index].scrapped_flag === undefined || props.products[acitve_index].items[props.edit_item_index].scrapped_flag === false) {
        setEditedItemUrl(props.products[acitve_index].items[props.edit_item_index].DetailPageURL)
      } else if ( props.products[acitve_index].items[props.edit_item_index].scrapped_flag === true ) {
        setEditedItemUrl(props.products[acitve_index].items[props.edit_item_index].detail_page_url)
      } 
    }
  }, [props.edit_item_index])

  const handleClose = () => {
    props.setEditItemIndex(-1)
    props.setOpen(false);
  };

  const handleSave = () => {
    try {
      (async () => {
        setIsLoading(true)
        let acitve_index = props.products.findIndex((ele:any) => ele.active === true)
        let update_result = await update_item(props.products[acitve_index]._id, props.edit_item_index, edited_item_url)

        if ( update_result.status === 'success' ) {
          // console.log(update_result.update_result)
          props.setProducts((prev: any[]) => {
            let next = [...prev]
            let acitve_index = next.findIndex((ele:any) => ele.active === true)

            next[acitve_index].items[props.edit_item_index] = update_result.update_result.items[props.edit_item_index]
            // if (next[acitve_index].items[props.edit_item_index].scrapped_flag === undefined || next[acitve_index].items[props.edit_item_index].scrapped_flag === false) {
            //   next[acitve_index].items[props.edit_item_index].DetailPageURL = edited_item_url
            //   next[acitve_index].items[props.edit_item_index].ItemInfo.ByLineInfo.Brand.DisplayValue = update_result.update_result.items[props.edit_item_index].ItemInfo.ByLineInfo.Brand.DisplayValue
            //   next[acitve_index].items[props.edit_item_index].ItemInfo.Title.DisplayValue = update_result.update_result.items[props.edit_item_index].ItemInfo.Title.DisplayValue
            // } else if ( next[acitve_index].items[props.edit_item_index].scrapped_flag === true ) {
            //   next[acitve_index].items[props.edit_item_index].DetailPageURL = edited_item_url
            //   next[acitve_index].items[props.edit_item_index].ItemInfo.ByLineInfo.Brand.DisplayValue = update_result.update_result.items[props.edit_item_index].ItemInfo.ByLineInfo.Brand.DisplayValue
            //   next[acitve_index].items[props.edit_item_index].ItemInfo.Title.DisplayValue = update_result.update_result.items[props.edit_item_index].ItemInfo.Title.DisplayValue
            // } 

            

            return next
          })
          setIsLoading(false)
          props.setOpen(false);
        } else if ( update_result.status === 'error' ) {
          setIsLoading(false)
          props.setOpen(false);
        }
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedItemUrl(e.target.value)
  }

  return (
    isLoading === true ? 
        <> 
          <div>
            <Dialog open={open} onClose={handleClose} fullWidth >
              <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={isLoading}
                  // onClick={handleClose}
              >
                  <CircularProgress color="inherit"/>
                  <p>&nbsp;&nbsp; Saving Data ...</p>
              </Backdrop>
              <DialogTitle>Edit Item's Url</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Url:
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  multiline
                  rows={10}
                  maxRows={10}
                  value={edited_item_url}
                  onChange={handleCategoryChange}
                  // onKeyDown={handleKeyDown}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </div>
        </> :
        <>
          <div>
            <Dialog open={open} onClose={handleClose} fullWidth >
              <DialogTitle>Edit Item's Url</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Url:
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  multiline
                  rows={10}
                  maxRows={10}
                  value={edited_item_url}
                  onChange={handleCategoryChange}
                  // onKeyDown={handleKeyDown}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
    
  );
}
