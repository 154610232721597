import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button, Box   } from '@mui/material'
import { recordUserAndIds } from 'modules/api/market-api'
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface Props {
    item: any,
    num: number,
}

const ProductBox = (props: Props) => {
    const { item, num } = props;
    // console.log(item)
    const selector = useSelector((state: any) => state)

    const recordIds = (e: any) => {
        e.stopPropagation()
        let gclid = localStorage.getItem('gclid')
        let msclkid = localStorage.getItem('msclkid')
        let search = localStorage.getItem('search')
        let amazon_tracking_id = localStorage.getItem('amazon_tracking_id')
        let _id = localStorage.getItem('_id')
        recordUserAndIds(gclid, msclkid, amazon_tracking_id, item.scrapped_flag === true ? item.detail_page_url : item.DetailPageURL, search, _id, item.levanta_obj)
            .then(result => {
            })
            .catch(err => {
                // console.log(err)
            })
    }

    return (
        <CustomA href={item.scrapped_flag === true ? item.detail_page_url : item.DetailPageURL} target="_blank" className='wrapper_a' onClick={(e:any) => recordIds(e)}>
            <CustomBox className="parent_box">
                <CustomBox className="left_box">
                    {
                        item.scrapped_flag === true ? 
                        <img src={item.img_url ? item.img_url : require('assets/1.jpg').default} alt="" width="120" height="120" />
                        :
                        <img src={item.Images.Primary.Large.URL ? item.Images.Primary.Large.URL : require('assets/1.jpg').default} alt="" width="120" height="120" />

                    }
                </CustomBox>
                <CustomBox className="center_box">
                    <Box className="main_box">
                        <Box className="part_box1">
                            <Box className="part_box1_right">
                            {
                                item.scrapped_flag === true ? 
                                item.brand
                                :
                                item.ItemInfo.ByLineInfo != null && item.ItemInfo.ByLineInfo.Brand != null ? item.ItemInfo.ByLineInfo.Brand.DisplayValue : ''

                            }
                                
                                
                            </Box>
                            {
                                num === 1 ? 
                                <div className="rating">
                                    <span className="number">10.0</span>
                                    <span style={{ margin: '0 10px' }}><CustomStarIcon /><CustomStarIcon /><CustomStarIcon /><CustomStarIcon /><CustomStarIcon /></span>
                                    <span className="total">({item.reviews_count_r} reviews)</span>
                                </div> : 
                                <div className="rating">
                                    <span className="number">9.8</span>
                                    <span style={{ margin: '0 10px' }}><CustomStarIcon /><CustomStarIcon /><CustomStarIcon /><CustomStarIcon /><CustomStarHalfIcon /></span>
                                    <span className="total">({item.reviews_count_r} reviews)</span>
                                </div>
                            }
                            
                        </Box>
                        <CustomA href={item.scrapped_flag === true ? item.detail_page_url : item.DetailPageURL} target="_blank" onClick={(e:any) => recordIds(e)}>
                            <Box className="part_box2">
                                {/* {item.ItemInfo.Title.DisplayValue} */}
                                {item.advantage_array?.length > 0 ?
                                item.advantage_array.map((ele:any, index: number) => {
                                    return <div className='list_text_div'> <CustomCheckCircleOutlineIcon /> &nbsp;{ele}</div>
                                })
                                :<>{ item.scrapped_flag === true ? item.title : item.ItemInfo.Title.DisplayValue}</>
                            }
                                </Box>
                        </CustomA>
                    </Box>
                </CustomBox>
                <CustomBox className="right_box">
                    <CustomBox className='right_box_wrapper'>
                        <CustomA href={item.scrapped_flag === true ? item.detail_page_url : item.DetailPageURL} target="_blank" onClick={(e:any) => recordIds(e)}><CustomButton sx={{ textTransform: "none" }} >Check Price</CustomButton></CustomA>
                        <img src={require('assets/amazonButtonUnderImage.svg').default} alt="" />
                        <p className="picked">Picked by {item.picked_users_count} people today</p>
                    </CustomBox>
                </CustomBox>
                <span className="itemNumber">{num}</span>
                {
                    num === 1 ? 
                    <div className="ribbon">Best Overall</div>
                    : <></>
                }
            </CustomBox>
        </CustomA>
    )
}

const CustomStarIcon = styled(StarIcon)`
    color: #f8b200;
`

const CustomStarHalfIcon = styled(StarHalfIcon)`
    color: #f8b200;
`

const CustomA = styled.a`
    text-decoration: none;
    width: 100%;
    &.wrapper_a {
        margin-bottom: 20px!important;
        border: 2px solid #EDEDED;
        border-radius: 22px;
        box-shadow: 0 0 10px rgba(0,0,0,0.08)!important;
        transition: all linear 0.2s;

        &:hover {
            border: 2px solid #ff8d00!important;
        }  
    }    
`

const CustomButton = styled(Button)`
`

const CustomCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)`
    color: gray;
    font-size: 15px!important;
`

const CustomBox = styled(Box)`
        width: 100%;
        height: 180px;
        display: flex;
        background: white;
        // margin: 10px 0;
        border-radius: 22px;
        position: relative;
        
        div.list_text_div {
            display: flex;
            align-items: center;
        }

        div.rating {
            text-decoration: none;
            color: #000;
            margin-left: 10px;
            display: flex;

            span.number {
                font-weight: 600;
            }
            
            span.total {
                color: gray;
            }
        }

        p.picked {
            color: #999999;
            padding-top: 10px;
            margin-top: 10px;
            border-top: 1px solid #e2e0da;
            font-size: 15px;
            margin-bottom: 0;
            // -webkit-letter-spacing: -0.1px;
            // -moz-letter-spacing: -0.1px;
            // -ms-letter-spacing: -0.1px;
            letter-spacing: -0.1px;
            line-height: 18px;
            width: 100%;
            text-align: center;
        }

        span.itemNumber {
            background-color: #fff;
            border-radius: 100%;
            display: block;
            height: 40px;
            line-height: 37px;
            width: 40px;
            border: 2px solid #000;
            position: absolute;
            top: 50%;
            margin-top: -20px;
            left: -20px;
            color: #000;
            // font-family: 'NT Somic Medium';
            // font-family: Inter;
            text-align: center;
            z-index: 1;
            font-size: 13px;
            box-sizing: border-box;
        }

        div.ribbon {
            background-color: #5b31dd;
            color: #ffffff;
            font-size: 12px;
            font-family: GT Pressura,sans-serif;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 0 10px;
            line-height: 30px;
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 1;
        }

        div.ribbon:after {
            border-color: #5b31dd transparent #5b31dd #5b31dd;
            right: -20px;
            border-width: 15px;
            height: 0;
            width: 0;
            content: '';
            position: absolute;
            border-style: solid;
            top: 0px;
        }

        .left_box {
            background: white;
            margin: 0;
            width: 180px;
            display: flex!important;
            align-items: center!important;
            justify-content: center!important;
            padding-left: 30px;
            img { 
                // width: 90%!important;
                // height: 90%!important;
                object-fit: contain;
                
            }
        }
        .center_box {
            background: white;
            margin: 0;
            width: 100%;
            flex-grow: 1;
            display: flex!important;
            align-items: center!important;
            justify-content: start!important;
            padding-left: 30px;
            padding-right: 30px;
            img {
                width: 133px;
                height: 24px;
                margin-top: 5px;
              }
            .main_box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .part_box1{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-bottom: 10px;
                    
                    .part_box1_right {
                        font-family: GT Pressura,sans-serif;
                        font-weight: 700;
                        line-height: 1.33;
                        font-size: 24px;
                        letter-spacing: 0.01em;
                        color: #0f1a16;
                    }
                }
                .part_box2{
                    margin: 0px;
                    color: #000000;
                    // color: #008472;
                    // color: #1275bb;
                    display: -webkit-box;
                    // -webkit-line-clamp:  2;
                    -webkit-box-orient: vertical;
                    // display: flex;
                    overflow: hidden;
                    font-family: Inter, Arial, sans-serif;
                    // font-weight: 600;
                    font-size: 15px;
                    cursor: pointer;
                    &:hover {
                        // background-color: #edecec;
                    }
                }
            }
        }
        .right_box {
            background: white;
            margin: 0;
            width: 200px;
            display: flex!important;
            align-items: center!important;
            justify-content: end!important;
            padding-right: 45px;
            img {
                width: 133px;
                height: 24px;
                margin-top: 5px;
              }
            .MuiBox-root {
                width: 170px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center!important;
            }
            &.right_box_wrapper {
                // width: 170px;
                // display: flex;
                // flex-direction: column;
                // align-items: center;
                // justify-content: center!important;
            }
        }
        ${CustomButton} {
            // width: 170px;
            width: 100%;
            height: 50px;
            padding: 0 10px;
            cursor: pointer;
            font-family: sans-serif;
            font-weight: 600;
            line-height: 1.33;
            font-size: 14px!important;
            letter-spacing: 0.03em!important;
            color: white!important;
            background-color: #f6ae18!important;
            border: 1px solid #f6ae18!important;
            text-transform: uppercase;
            
            &:hover {
                border: 1px solid #f2a842!important;
                background-color: #f09637!important;
            }  
        }
`

export default ProductBox