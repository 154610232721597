import React from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
 
  interface Column {
    id: 'username' | 'gclid' | 'msclkid' | 'amazon_tracking_id' | 'visited_url' | 'time_stamp' | 'levanta_source_name' | 'levanta_source_id' | 'levanta_link_id' | 'ip_address' | 'location';
    label: string;
    minWidth?: number;
    align?: 'center';
  }
  
  const columns: readonly Column[] = [
    { 
      id: 'username', 
      label: 'User Name', 
    },
    { 
      id: 'gclid', 
      label: 'Gcl Id', 
    },
    {
      id: 'msclkid',
      label: 'Msclk Id'
    },
    {
      id: 'amazon_tracking_id',
      label: 'Tracking Id',
    },
    {
      id: 'visited_url',
      label: 'Visited URL',
    },
    {
      id: 'levanta_source_name',
      label: 'levanta source name'
    },
    {
      id: 'levanta_source_id',
      label: 'levanta source id'
    },
    {
      id: 'levanta_link_id',
      label: 'levanta link id'
    },
    {
      id: 'ip_address',
      label: 'IP Adress',
    },
    {
      id: 'location',
      label: 'Location',
    },
    {
      id: 'time_stamp',
      label: 'Time Stamp',
    }
  ];
  
  interface Data {
    username: string;
    gclid: string;
    msclkid: string;
    amazon_tracking_id: string;
    visited_url: string;
    levanta_source_name: string;
    levanta_source_id: string;
    levanta_link_id: string;
    time_stamp: string;
    ip_address: string;
    location: string;
  }

  interface Props {
    page: number,
    setPage: any,
    rowsPerPage: number,
    setRowsPerPage: any,
    count: number,
    rows: Data[]
  }

  const Records = (props: Props) => {
    const { page, setPage, rowsPerPage, setRowsPerPage, rows, count } = props
    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: '75vh' }}>
              <Table stickyHeader aria-label="sticky table" sx={{ tableLayout: 'fixed', overflowWrap: 'break-word'}}>
                <TableHead>
                    <TableRow>
                    {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                    ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                  {
                    rows.length === 0 ? '' :
                    // rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    rows.map((row: Data, index: number) => {
                      return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {
                          columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            );
                          })
                        }
                      </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              // count={rows.length}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
    </>                                                               
    );
};

export default Records